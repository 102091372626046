import { CommonMargin } from 'components/_layout/CommonMargin';
import React from 'react';
import { Industries } from 'sections/Industries/Industries';

export default () => {
    return (
        <CommonMargin>
            <Industries />
        </CommonMargin>
    );
};

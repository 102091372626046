import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { B0 } from 'styles/Typography.styled';

export const PointerSpan = styled.span(
    () => css`
        cursor: pointer;
    `,
);

export const Wrapper = styled.div(
    ({ theme: { colors } }) => css`
        display: flex;
        gap: 50px;
        justify-content: space-between;
        position: relative;
        cursor: pointer;

        &:after {
            width: 100%;
            content: '';
            border-bottom: 1px solid ${colors.border};
            position: absolute;
            bottom: -128px;
            left: 0;

            ${mediaQueries.xs} {
                display: none;
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }

        ${mediaQueries.ipadV} {
            flex-direction: column;
            gap: 24px;
        }
    `,
);

export const BlogItemImage = styled.img`
    width: 405px;
    object-fit: cover;
    max-height: 300px;

    ${mediaQueries.ipadV} {
        width: 100%;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${mediaQueries.xs} {
        padding: 0 20px;
    }
`;

export const BlogItemTitle = styled(B0)`
    margin-bottom: 16px;
    line-height: initial;

    ${mediaQueries.xs} {
        margin-bottom: 20px;
    }
`;

export const TagTile = styled.div(
    ({ theme: { colors, fontSizes, fontFamily } }) =>
        css`
            background: #686868;
            color: ${colors.greylikeText};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f12};
            padding: 8px 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
        `,
);

export const BlogItemExcerpt = styled.div(
    ({ theme: { colors, fontFamily, fontSizes } }) => css`
        color: ${colors.greylikeText};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.b3};
        line-height: 26px;
        margin-bottom: 24px;
    `,
);

export const BlogItemReadingLength = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) =>
        css`
            color: ${colors.greylikeText};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f12};
            font-weight: ${fontWeight.medium};
        `,
);

export const BorderSeparator = styled.div(
    ({ theme: { colors } }) => css`
        width: 100%;
        height: 1px;
        border-top: 1px solid ${colors.greylikeText};
        margin-top: 64px;
        margin-bottom: 64px;
    `,
);

import { ArrowButton } from 'components/_universal/ArrowButton';
import * as Common from 'components/_universal/Common';
import { B2 } from 'styles/Typography.styled';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const SideMenu = styled(Common.Div)(
    () =>
        css`
            width: 507px;
            height: 100%;

            ${mediaQueries.md} {
                width: 300px;
            }
        `,
);

export const MenuItem = styled(Common.Div)<{ isActive?: boolean }>(
    ({ theme: { colors }, isActive }) =>
        css`
            width: 100%;
            height: 85px;
            padding-left: 166px;
            display: flex;
            align-items: center;
            background: ${isActive ? colors.section : colors.background};
            color: ${isActive ? colors.hoverBlue : colors.text};
            cursor: pointer;

            ${mediaQueries.md} {
                padding-left: 50px;
            }
        `,
);

export const MenuAbsoluteContainer = styled(Common.Div)(
    () =>
        css`
            top: 300px;
            left: 0;
            width: 100%;
            display: flex;
            border-top: 1px solid white;

            ${mediaQueries.sm} {
                flex-direction: column;
                border: none;
            }
        `,
);

export const IndustryContent = styled(Common.Div)(
    () =>
        css`
            display: flex;
            flex-direction: column;
            flex: 1;
        `,
);

export const StatsWithBg = styled(Common.Div)<{ bg: string }>(
    ({ bg }) =>
        css`
            background-image: url(${bg});
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 420px;
            display: flex;
            gap: 100px;
            padding-left: 30px;
            padding-top: 210px;
            position: relative;

            div {
                p {
                    max-width: 156px;
                }
            }

            ${mediaQueries.ipadH} {
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                padding: 30px;
                height: auto;

                h2 {
                    font-size: 40px;
                }
            }

            ${mediaQueries.sm} {
                border-top: 1px solid white;
            }
        `,
);

export const IndustryDescription = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            background: ${colors.section};

            padding: 44px 156px 0 30px;

            ${mediaQueries.md} {
                padding: 44px 60px 0 30px;
            }

            ${mediaQueries.sm} {
                padding: 44px 30px 0 30px;
            }
        `,
);

export const IndustryLogo = styled(Common.Svg)(
    () =>
        css`
            max-height: 58px;
        `,
);

export const DescriptionText = styled(B2)(
    () =>
        css`
            line-height: 25px;
        `,
);

export const ReadMoreButton = styled(ArrowButton)(
    () =>
        css`
            justify-content: space-between;
        `,
);

export const NoWrapSection = styled(Common.Div)(
    () =>
        css`
            white-space: nowrap;

            h2 {
                font-size: 47px;
            }
        `,
);

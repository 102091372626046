import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { B2 } from 'styles/Typography.styled';

export const StepButton = styled(Common.Div)(
    ({ theme: { colors, spacing } }) =>
        css`
            background: ${colors.buttons};
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${spacing(1)} ${spacing(3)};
            margin-bottom: ${spacing(4)};
            gap: 32px;
            width: 255px;
            height: 41px;

            p {
                font-size: 14px;
            }

            ${mediaQueries.xs} {
                width: 100%;
            }
        `,
);

export const ArrowButton = ({
    onClick,
    disabled = false,
    text,
    className = '',
}: {
    onClick: () => void;
    disabled?: boolean;
    text: string;
    className?: string;
}) => {
    return (
        <StepButton onClick={onClick} className={className}>
            <B2>{text}</B2>
            <Common.Svg src={ArrowSvg} />
        </StepButton>
    );
};

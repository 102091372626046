import ArrowLeft from 'assets/images/Industries/arrowLeft.svg';
import ArrowRight from 'assets/images/Industries/arrowRight.svg';
import * as Common from 'components/_universal/Common';
import { swiperNavigationStyles } from 'components/_universal/commonStyles';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { INDUSTRIES_MENU_ITEMS, TIndustry } from 'shared/data/Industries.data';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { B1 } from 'styles/Typography.styled';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

export const MenuWrapper = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.section};
            display: flex;
            gap: 14px;
            position: relative;
            margin-top: 3px;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 12px;
            padding-top: 12px;
            height: 140px;

            ${mediaQueries.xs} {
                height: auto;
            }
        `,
);

export const SwiperWrapper = styled(Common.Div)(
    () =>
        css`
            width: calc(100% - 140px);
            .swiper-slide {
                justify-content: center;
                display: flex;
            }

            .swiper-wrapper {
                height: 70px;
            }

            ${swiperNavigationStyles};
        `,
);

export const MenuLink = styled(B1)(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.hoverBlue};
            font-size: 100%;
        `,
);

export const SwiperLeftArrowBack = styled(Common.Div)(
    () =>
        css`
            width: 40px;
            height: 40px;
            background-image: url(${ArrowLeft});
            background-repeat: no-repeat;
            background-position: center;
            margin-left: 16px;
        `,
);

export const SwiperRightArrowNext = styled(Common.Div)(
    () =>
        css`
            width: 40px;
            height: 40px;
            background-image: url(${ArrowRight});
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 16px;
        `,
);

export const SlideImage = styled(Common.Svg)(() => css``);

interface IMenuSwiperProps {
    tab: TIndustry;
    isVisible?: boolean;
}

export const MenuSwiper = ({ tab, isVisible }: IMenuSwiperProps) => {
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();

    const currentIndex = INDUSTRIES_MENU_ITEMS.findIndex((e) => e.link === tab);

    useEffect(() => {
        if (swiperRef && isVisible) {
            swiperRef?.slideTo(currentIndex + 1);
        }
    }, [currentIndex, swiperRef]);

    const calculateTabIndex = (next = true) => {
        const tabsLength = INDUSTRIES_MENU_ITEMS.length;
        if (next) {
            return (currentIndex + 1) % tabsLength;
        } else {
            return (currentIndex - 1 + tabsLength) % tabsLength;
        }
    };

    const handlePrevious = () => {
        window.location.hash =
            INDUSTRIES_MENU_ITEMS[calculateTabIndex(false)].link;
        swiperRef?.slidePrev();
    };

    const handleNext = () => {
        window.location.hash =
            INDUSTRIES_MENU_ITEMS[calculateTabIndex(true)].link;
        swiperRef?.slideNext();
    };

    return isVisible ? (
        <MenuWrapper>
            <SwiperLeftArrowBack onClick={handlePrevious} />
            <SwiperWrapper>
                <Swiper
                    onSwiper={setSwiperRef}
                    className={'swiper'}
                    loop
                    modules={[Pagination, Navigation]}
                    pagination={{ clickable: true }}
                    allowTouchMove={false}
                >
                    {INDUSTRIES_MENU_ITEMS.map(({ name, link }) => (
                        <SwiperSlide key={name}>
                            <MenuLink onClick={() => navigate(link)}>
                                {name}
                            </MenuLink>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </SwiperWrapper>

            <SwiperRightArrowNext onClick={handleNext} />
        </MenuWrapper>
    ) : null;
};
